<template>
  <v-layout row wrap>
    <v-flex xs12 class="elevation-1">
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$tc('_searchByRoleName')"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          class="tw-bg-white"
          no-data-text="No roles found for wallets. Start creating new role or contact administrator."
          :headers="headers"
          :items="roles"
          :pagination.sync="pagination"
          :loading="isLoading ? 'accent' : false"
          :rows-per-page-items="pageRows"
          :search="search"
        >
          <template slot="items" slot-scope="props">
            <tr>
              <td v-for="(head, index) of headerKeys" :key="index">
                {{ props.item[head] }}
              </td>
              <td>
                <UiButton
                  v-if="checkScope(scopeLiterals.WalletsDelete)"
                  class="tw-w-24 tw-justify-center"
                  color="white"
                  @click.native.stop="deleteRole(props.item)"
                >
                  <span>Delete</span>
                </UiButton>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { WalletService } from '@/services/walletService';

import UiButton from '../ui/UiButton.vue';

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    UiButton,
  },
  props: [],
  data() {
    return {
      pagination: {
        sortBy: 'name',
      },
      roles: [],
      orgs: [],
      isLoading: 0,
      viewDialog: false,
      search: '',
    };
  },
  mounted() {
    this.isLoading = 1;
    this.refresh();
  },
  computed: {
    features() {
      return this.$store.getters.features;
    },
    pageRows() {
      return [10, 50, 100, { text: '$vuetify.dataIterator.rowsPerPageAll', value: -1 }];
    },
    headers() {
      return [
        {
          text: this.$tc('_roleId'),
          align: 'left',
          value: 'id',
          width: '100px',
        },
        {
          text: this.$t('_roleName'),
          align: 'center',
          value: 'name',
          width: '50%',
        },
        {
          text: this.$tc('_action', 2),
          align: 'right',
          value: 'actions',
          width: '15%',
          show: true,
          sortable: false,
        },
      ];
    },
    headerKeys() {
      return this.headers.filter((h) => h.value !== 'actions').map((h) => h.value);
    },
  },
  watch: {
    '$store.state.addedWalletRole': function () {
      this.refresh();
    },
  },
  methods: {
    async getWalletRoles() {
      const orgId = this.$store.state.currentOrg.id;
      // init wallet service
      const walletSvc = new WalletService(this.checkFeatureFlag('soda-report'));
      return await walletSvc.getWalletRoles(orgId);
    },
    async refresh() {
      // load roles
      this.roles = await this.getWalletRoles().then((roles) => {
        return roles?.map((role) => ({ ...role, actions: null }));
      });

      this.isLoading = 0;
    },

    async deleteRole(role) {
      const confirmed = confirm('Are you sure you want to delete this role?');
      if (confirmed) {
        this.isLoading = 1;
        const orgId = this.$store.state.currentOrg.id;
        const walletSvc = new WalletService(this.checkFeatureFlag('soda-report'));
        await walletSvc.deleteRole(orgId, role.id);
        // process:
        this.isLoading = 0;
        this.refresh();
      }
    },
  },
};
</script>
