















import Component from 'vue-class-component';

import { BaseVue } from '@/BaseVue';
import UiButton from '@/components/ui/UiButton.vue';
import UiTextEdit from '@/components/ui/UiTextEdit.vue';
import { WalletService } from '@/services/walletService';

@Component({
  components: {
    UiTextEdit,
    UiButton,
  },
})
export default class WalletsNew extends BaseVue {
  public walletRole = null;

  async saveRole() {
    if (this.walletRole) {
      const orgId = this.$store.state.currentOrg.id;
      // init wallet service
      const walletSvc = new WalletService(this.checkFeatureFlag('soda-report'));
      await walletSvc.createRole(orgId, this.walletRole);
      // return value to the parent UI
      this.$emit('saved', this.walletRole);
    }
  }
}
