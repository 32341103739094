




























import Component from 'vue-class-component';

import { BaseVue } from '@/BaseVue';
// custom components
import TooltipSelect from '@/components/tooltip/TooltipSelect.vue';
// ui components
import UiButton from '@/components/ui/UiButton.vue';
import UiModal from '@/components/ui/UiModal.vue';
import WalletRolesList from '@/components/wallets/WalletRolesList.vue';
import WalletRolesNew from '@/components/wallets/WalletRolesNew.vue';
import { MUT_ADD_WALLET_ROLE, MUT_SNACKBAR } from '@/store';

import { checkScope } from '../../utils/security';

export interface IWalletRole {
  name: string;
}

@Component({
  components: {
    UiButton,
    UiModal,
    WalletRolesNew,
    WalletRolesList,
    TooltipSelect,
  },
})
export default class WalletsRoles extends BaseVue {
  showCreateModal = false;

  // mounted
  mounted() {
    //
  }

  data() {
    return {
      showCreateModal: this.showCreateModal,
    };
  }

  public get isReadonly() {
    return !checkScope(this.scopeLiterals.WalletsRead);
  }

  createRole() {
    this.showCreateModal = true;
  }

  async completedSave(role: IWalletRole) {
    // update store state
    this.$store.commit(MUT_ADD_WALLET_ROLE, role);

    this.$store.commit(MUT_SNACKBAR, {
      color: 'success',
      message: 'New wallet role was added.',
    });
    this.showCreateModal = false;
  }
}
